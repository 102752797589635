import keyMirror from 'keymirror';
import {createAction} from 'redux-actions';
import {BASE_URL, ENDPOINTS} from './middleware';

export const apiActionTypes = keyMirror({
  API_CALL_STARTED: null,
  API_CALL_ENDED: null,

  //GENERAL----------------------------------------------
  API_LOGIN: null,
  API_LOGIN_SUCCESS: null,
  API_LOGIN_UNAUTHORIZED: null,
  API_LOGIN_ERROR: null,
  API_LOGOUT: null,

  API_GET_PROFILE: null,
  API_PROFILE_SUCCESS: null,
  API_PROFILE_ERROR: null,

  API_GET_MANIFEST: null,
  API_MANIFEST_SUCCESS: null,
  API_MANIFEST_ERROR: null,

  API_GET_COMMUNITIES: null,
  API_COMMUNITIES_SUCCESS: null,
  API_COMMUNITIES_ERROR: null,

  API_GET_ROLES_LIST: null,
  API_ROLES_LIST_SUCCESS: null,
  API_ROLES_LIST_ERROR: null,

  API_GET_INTERVENTION_ZONES: null,
  API_INTERVENTION_ZONES_SUCCESS: null,
  API_INTERVENTION_ZONES_ERROR: null,
  API_RESET_INTERVENTION_ZONES: null,

  API_GET_COMMUNITY_DETAILS: null,
  API_GET_COMMUNITY_DETAILS_SUCCESS: null,
  API_GET_COMMUNITY_DETAILS_ERROR: null,

  API_GET_COMMUNITY_FILES: null,
  API_GET_COMMUNITY_FILES_SUCCESS: null,
  API_GET_COMMUNITY_FILES_ERROR: null,
  API_RESET_COMMUNITY_FILES: null,

  //INTERVENTIONS----------------------------------------------
  API_GET_INTERVENTION: null,
  API_GET_INTERVENTION_SUCCESS: null,
  API_GET_INTERVENTION_ERROR: null,

  API_POST_CREATE_INTERVENTION: null,
  API_POST_CREATE_INTERVENTION_SUCCESS: null,
  API_POST_CREATE_INTERVENTION_ERROR: null,

  API_POST_CREATE_PLANTATION: null,
  API_POST_CREATE_PLANTATION_SUCCESS: null,
  API_POST_CREATE_PLANTATION_ERROR: null,

  API_DELETE_INTERVENTION: null,
  API_DELETE_INTERVENTION_SUCCESS: null,
  API_DELETE_INTERVENTION_ERROR: null,

  API_UPDATE_INTERVENTION: null,
  API_UPDATE_INTERVENTION_SUCCESS: null,
  API_UPDATE_INTERVENTION_ERROR: null,

  API_UPDATE_PLANTATION: null,
  API_UPDATE_PLANTATION_SUCCESS: null,
  API_UPDATE_PLANTATION_ERROR: null,

  API_UPDATE_INTERVENTION_EXECUTION: null,
  API_UPDATE_INTERVENTION_EXECUTION_SUCCESS: null,
  API_UPDATE_INTERVENTION_EXECUTION_ERROR: null,

  API_GET_INTERVENTION_ZONE_DETAIL: null,
  API_GET_INTERVENTION_ZONE_DETAIL_SUCCESS: null,
  API_GET_INTERVENTION_ZONE_DETAIL_ERROR: null,
  API_CLEAN_INTERVENTION_ZONE_DETAIL: null,

  API_DOWNLOAD_INTERVENTION_ZONE_GEOMETRY: null,
  API_DOWNLOAD_INTERVENTION_ZONE_GEOMETRY_SUCCESS: null,
  API_DOWNLOAD_INTERVENTION_ZONE_GEOMETRY_ERROR: null,

  API_POST_NEVER_DO: null,
  API_POST_NEVER_DO_SUCCESS: null,
  API_POST_NEVER_DO_ERROR: null,

  //EXECUTIONS----------------------------------------------
  API_POST_CREATE_EXECUTION: null,
  API_POST_CREATE_EXECUTION_SUCCESS: null,
  API_POST_CREATE_EXECUTION_ERROR: null,

  API_UPDATE_EXECUTION: null,
  API_UPDATE_EXECUTION_SUCCESS: null,
  API_UPDATE_EXECUTION_ERROR: null,

  API_DELETE_EXECUTION: null,
  API_DELETE_EXECUTION_SUCCESS: null,
  API_DELETE_EXECUTION_ERROR: null,

  //FILES----------------------------------------------
  API_UPLOAD_COMMUNITY_FILE: null,
  API_UPLOAD_COMMUNITY_FILE_SUCCESS: null,
  API_UPLOAD_COMMUNITY_FILE_ERROR: null,

  API_UPLOAD_FILE: null,
  API_UPLOAD_FILE_SUCCESS: null,
  API_UPLOAD_FILE_ERROR: null,

  API_DELETE_FILE: null,
  API_DELETE_FILE_SUCCESS: null,
  API_DELETE_FILE_ERROR: null,

  API_DELETE_COMMUNITY_FILE: null,
  API_DELETE_COMMUNITY_FILE_SUCCESS: null,
  API_DELETE_COMMUNITY_FILE_ERROR: null,

  API_GET_FILE_DETAIL: null,
  API_GET_FILE_DETAIL_SUCCESS: null,
  API_GET_FILE_DETAIL_ERROR: null,

  API_DOWNLOAD_FILE: null,
  API_DOWNLOAD_FILE_SUCCESS: null,
  API_DOWNLOAD_FILE_ERROR: null,

  API_DOWNLOAD_TOKEN_FILE: null,
  API_DOWNLOAD_TOKEN_FILE_SUCCESS: null,
  API_DOWNLOAD_TOKEN_FILE_ERROR: null,

  API_DOWNLOAD_COMMUNITY_FILES: null,
  API_DOWNLOAD_COMMUNITY_FILES_SUCCESS: null,
  API_DOWNLOAD_COMMUNITY_FILES_ERROR: null,

  //ANNUALPLANS----------------------------------------------
  API_GET_ANNUAL_PLANS: null,
  API_GET_ANNUAL_PLANS_SUCCESS: null,
  API_GET_ANNUAL_PLANS_ERROR: null,
  API_RESET_ANNUAL_PLANS: null,

  API_POST_CREATE_ANNUAL_PLAN: null,
  API_CREATE_ANNUAL_PLAN_SUCCESS: null,
  API_CREATE_ANNUAL_PLAN_ERROR: null,

  API_POST_COMPLETE_ANNUAL_PLAN: null,
  API_COMPLETE_ANNUAL_PLAN_SUCCESS: null,
  API_COMPLETE_ANNUAL_PLAN_ERROR: null,

  API_DELETE_ANNUAL_PLAN: null,
  API_DELETE_ANNUAL_PLAN_SUCCESS: null,
  API_DELETE_ANNUAL_PLAN_ERROR: null,

  API_UPDATE_INTERVENTION_ANNUAL_PLAN: null,
  API_UPDATE_INTERVENTION_ANNUAL_PLAN_SUCCESS: null,
  API_UPDATE_INTERVENTION_ANNUAL_PLAN_ERROR: null,

  API_DOWNLOAD_ANNUAL_PLAN: null,
  API_DOWNLOAD_ANNUAL_PLAN_SUCCESS: null,
  API_DOWNLOAD_ANNUAL_PLAN_ERROR: null,

  API_POST_IN_PROCEDURE_ANNUAL_PLAN: null,
  API_IN_PROCEDURE_ANNUAL_PLAN_SUCCESS: null,
  API_IN_PROCEDURE_ANNUAL_PLAN_ERROR: null,

  //COMMONERS----------------------------------------------
  API_GET_COMMONERS: null,
  API_GET_COMMONERS_SUCCESS: null,
  API_GET_COMMONERS_ERROR: null,
  API_RESET_COMMONERS: null,

  API_CREATE_COMMONER: null,
  API_CREATE_COMMONER_SUCCESS: null,
  API_CREATE_COMMONER_ERROR: null,

  API_DELETE_COMMONER: null,
  API_DELETE_COMMONER_SUCCESS: null,
  API_DELETE_COMMONER_ERROR: null,

  API_UPDATE_COMMONER: null,
  API_UPDATE_COMMONER_SUCCESS: null,
  API_UPDATE_COMMONER_ERROR: null,

  API_INVITE_COMMONER: null,
  API_INVITE_COMMONER_SUCCESS: null,
  API_INVITE_COMMONER_ERROR: null,

  API_ACTIVATE_COMMONER: null,
  API_ACTIVATE_COMMONER_SUCCESS: null,
  API_ACTIVATE_COMMONER_ERROR: null,

  API_DEACTIVATE_COMMONER: null,
  API_DEACTIVATE_COMMONER_SUCCESS: null,
  API_DEACTIVATE_COMMONER_ERROR: null,

  //BUSINESS----------------------------------------------
  API_GET_BUSINESS: null,
  API_GET_BUSINESS_SUCCESS: null,
  API_GET_BUSINESS_ERROR: null,

  API_GET_BUSINESS_WITH_LOCATION: null,
  API_GET_BUSINESS_WITH_LOCATION_SUCCESS: null,
  API_GET_BUSINESS_WITH_LOCATION_ERROR: null,

  //MADERAPLUS----------------------------------------------
  MADERA_PLUS_GET_SPECIES: null,
  MADERA_PLUS_GET_SPECIES_SUCCESS: null,
  MADERA_PLUS_GET_SPECIES_ERROR: null,

  MADERA_PLUS_GET_DISTRICT: null,
  MADERA_PLUS_GET_DISTRICT_SUCCESS: null,
  MADERA_PLUS_GET_DISTRICT_ERROR: null,

  MADERA_PLUS_GET_DATA: null,
  MADERA_PLUS_GET_DATA_SUCCESS: null,
  MADERA_PLUS_GET_DATA_ERROR: null,

  API_POST_UPDATE_ELASTIC_MODULE: null,
  API_POST_UPDATE_ELASTIC_MODULE_SUCCESS: null,
  API_POST_UPDATE_ELASTIC_MODULE_ERROR: null,

  API_POST_PROSPECTING_POINTS: null,
  API_POST_PROSPECTING_POINTS_SUCCESS: null,
  API_POST_PROSPECTING_POINTS_ERROR: null,

  API_GET_PROSPECTING_POINTS: null,
  API_GET_PROSPECTING_POINTS_SUCCESS: null,
  API_GET_PROSPECTING_POINTS_ERROR: null,

  API_DELETE_PROSPECTING_POINTS: null,
  API_DELETE_PROSPECTING_POINTS_SUCCESS: null,
  API_DELETE_PROSPECTING_POINTS_ERROR: null,

  //ACCOUNT BOOK----------------------------------------------
  API_GET_ACCOUNT_ENTRIES: null,
  API_GET_ACCOUNT_ENTRIES_SUCCESS: null,
  API_GET_ACCOUNT_ENTRIES_ERROR: null,
  API_RESET_ACCOUNT_ENTRIES: null,

  API_CREATE_ACCOUNT_ENTRY: null,
  API_CREATE_ACCOUNT_ENTRY_SUCCESS: null,
  API_CREATE_ACCOUNT_ENTRY_ERROR: null,

  API_DELETE_ACCOUNT_ENTRY: null,
  API_DELETE_ACCOUNT_ENTRY_SUCCESS: null,
  API_DELETE_ACCOUNT_ENTRY_ERROR: null,

  API_UPDATE_ACCOUNT_ENTRY: null,
  API_UPDATE_ACCOUNT_ENTRY_SUCCESS: null,
  API_UPDATE_ACCOUNT_ENTRY_ERROR: null,

  //BATCHES----------------------------------------------
  API_GET_BATCHES: null,
  API_GET_BATCHES_SUCCESS: null,
  API_GET_BATCHES_ERROR: null,
  API_RESET_BATCHES: null,

  API_POST_CREATE_BATCH: null,
  API_POST_CREATE_BATCH_SUCCESS: null,
  API_POST_CREATE_BATCH_ERROR: null,

  API_POST_DELETE_BATCH: null,
  API_POST_DELETE_BATCH_SUCCESS: null,
  API_POST_DELETE_BATCH_ERROR: null,

  API_POST_UPDATE_BATCH: null,
  API_POST_UPDATE_BATCH_SUCCESS: null,
  API_POST_UPDATE_BATCH_ERROR: null,

  //MULTIPLE----------------------------------------------
  API_POST_MULTIPLE_EXECUTION: null,
  API_POST_MULTIPLE_EXECUTION_SUCCESS: null,
  API_POST_MULTIPLE_EXECUTION_ERROR: null,

  API_CREATE_MULTIPLE_INTERVENTIONS: null,
  API_CREATE_MULTIPLE_INTERVENTIONS_SUCCESS: null,
  API_CREATE_MULTIPLE_INTERVENTIONS_ERROR: null,

  API_CREATE_MULTIPLE_PLANTATIONS: null,
  API_CREATE_MULTIPLE_PLANTATIONS_SUCCESS: null,
  API_CREATE_MULTIPLE_PLANTATIONS_ERROR: null,

  API_UPDATE_MULTIPLE_INTERVENTIONS: null,
  API_UPDATE_MULTIPLE_INTERVENTIONS_SUCCESS: null,
  API_UPDATE_MULTIPLE_INTERVENTIONS_ERROR: null,

});

export default apiActionTypes;

export const apiCallStarted = createAction(apiActionTypes.API_CALL_STARTED);
export const apiCallEnded = createAction(apiActionTypes.API_CALL_ENDED);

//GENERAL----------------------------------------------------------------------
export const apiLogin = createAction(apiActionTypes.API_LOGIN);
export const apiLoginSuccess = createAction(apiActionTypes.API_LOGIN_SUCCESS);
export const apiLoginUnauthorized = createAction(apiActionTypes.API_LOGIN_UNAUTHORIZED);
export const apiLoginError = createAction(apiActionTypes.API_LOGIN_ERROR);
export const apiLogout = createAction(apiActionTypes.API_LOGOUT);

export const apiGetProfile = createAction(apiActionTypes.API_GET_PROFILE);
export const apiProfileSuccess = createAction(apiActionTypes.API_PROFILE_SUCCESS);
export const apiProfileError = createAction(apiActionTypes.API_PROFILE_ERROR);

export const apiGetManifest = createAction(apiActionTypes.API_GET_MANIFEST);
export const apiManifestSuccess = createAction(apiActionTypes.API_MANIFEST_SUCCESS);
export const apiManifestError = createAction(apiActionTypes.API_MANIFEST_ERROR);

export const apiGetCommunities = createAction(apiActionTypes.API_GET_COMMUNITIES);
export const apiCommunitiesSuccess = createAction(apiActionTypes.API_COMMUNITIES_SUCCESS);
export const apiCommunitiesError = createAction(apiActionTypes.API_COMMUNITIES_ERROR);

export const apiGetRolesList = createAction(apiActionTypes.API_GET_ROLES_LIST);
export const apiRolesListSuccess = createAction(apiActionTypes.API_ROLES_LIST_SUCCESS);
export const apiRolesListError = createAction(apiActionTypes.API_ROLES_LIST_ERROR);

export const apiGetInterventionZones = createAction(apiActionTypes.API_GET_INTERVENTION_ZONES);
export const apiInterventionZonesSuccess = createAction(apiActionTypes.API_INTERVENTION_ZONES_SUCCESS);
export const apiInterventionZonesError = createAction(apiActionTypes.API_INTERVENTION_ZONES_ERROR);
export const apiResetInterventionZones = createAction(apiActionTypes.API_RESET_INTERVENTION_ZONES);

export const apiGetCommunityDetails = createAction(apiActionTypes.API_GET_COMMUNITY_DETAILS);
export const apiGetCommunityDetailsSuccess = createAction(apiActionTypes.API_GET_COMMUNITY_DETAILS_SUCCESS);
export const apiGetCommunityDetailsError = createAction(apiActionTypes.API_GET_COMMUNITY_DETAILS_ERROR);

export const apiGetCommunityFiles = createAction(apiActionTypes.API_GET_COMMUNITY_FILES);
export const apiGetCommunityFilesSuccess = createAction(apiActionTypes.API_GET_COMMUNITY_FILES_SUCCESS);
export const apiGetCommunityFilesError = createAction(apiActionTypes.API_GET_COMMUNITY_FILES_ERROR);
export const apiResetCommunityFiles = createAction(apiActionTypes.API_RESET_COMMUNITY_FILES);

//INTERVENTIONS----------------------------------------------------------------------
export const apiGetIntervention = createAction(apiActionTypes.API_GET_INTERVENTION);
export const apiGetInterventionSuccess = createAction(apiActionTypes.API_GET_INTERVENTION_SUCCESS);
export const apiGetInterventionError = createAction(apiActionTypes.API_GET_INTERVENTION_ERROR);

export const apiCreateIntervention = createAction(apiActionTypes.API_POST_CREATE_INTERVENTION);
export const apiCreateInterventionSuccess = createAction(apiActionTypes.API_POST_CREATE_INTERVENTION_SUCCESS);
export const apiCreateInterventionError = createAction(apiActionTypes.API_POST_CREATE_INTERVENTION_ERROR);

export const apiCreatePlantation = createAction(apiActionTypes.API_POST_CREATE_PLANTATION);
export const apiCreatePlantationSuccess = createAction(apiActionTypes.API_POST_CREATE_PLANTATION_SUCCESS);
export const apiCreatePlantationError = createAction(apiActionTypes.API_POST_CREATE_PLANTATION_ERROR);

export const apiDeleteIntervention = createAction(apiActionTypes.API_DELETE_INTERVENTION);
export const apiDeleteInterventionSuccess = createAction(apiActionTypes.API_DELETE_INTERVENTION_SUCCESS);
export const apiDeleteInterventionError = createAction(apiActionTypes.API_DELETE_INTERVENTION_ERROR);

export const apiUpdateIntervention = createAction(apiActionTypes.API_UPDATE_INTERVENTION);
export const apiUpdateInterventionSuccess = createAction(apiActionTypes.API_UPDATE_INTERVENTION_SUCCESS);
export const apiUpdateInterventionError = createAction(apiActionTypes.API_UPDATE_INTERVENTION_ERROR);

export const apiUpdatePlantation = createAction(apiActionTypes.API_UPDATE_PLANTATION);
export const apiUpdatePlantationSuccess = createAction(apiActionTypes.API_UPDATE_PLANTATION_SUCCESS);
export const apiUpdatePlantationError = createAction(apiActionTypes.API_UPDATE_PLANTATION_ERROR);

export const apiUpdateInterventionExecution = createAction(apiActionTypes.API_UPDATE_INTERVENTION_EXECUTION);
export const apiUpdateInterventionExecutionSuccess = createAction(apiActionTypes.API_UPDATE_INTERVENTION_EXECUTION_SUCCESS);
export const apiUpdateInterventionExecutionError = createAction(apiActionTypes.API_UPDATE_INTERVENTION_EXECUTION_ERROR);

export const apiGetInterventionZoneDetail = createAction(apiActionTypes.API_GET_INTERVENTION_ZONE_DETAIL);
export const apiGetInterventionZoneDetailSuccess = createAction(apiActionTypes.API_GET_INTERVENTION_ZONE_DETAIL_SUCCESS);
export const apiGetInterventionZoneDetailError = createAction(apiActionTypes.API_GET_INTERVENTION_ZONE_DETAIL_ERROR);
export const apiCleanInterventionZoneDetail = createAction(apiActionTypes.API_CLEAN_INTERVENTION_ZONE_DETAIL);

export const apiDownloadInterventionZoneGeometry = createAction(apiActionTypes.API_DOWNLOAD_INTERVENTION_ZONE_GEOMETRY);
export const apiDownloadInterventionZoneGeometrySuccess = createAction(apiActionTypes.API_DOWNLOAD_INTERVENTION_ZONE_GEOMETRY_SUCCESS);
export const apiDownloadInterventionZoneGeometryError = createAction(apiActionTypes.API_DOWNLOAD_INTERVENTION_ZONE_GEOMETRY_ERROR);

export const apiPostNeverDo = createAction(apiActionTypes.API_POST_NEVER_DO);
export const apiPostNeverDoSuccess = createAction(apiActionTypes.API_POST_NEVER_DO_SUCCESS);
export const apiPostNeverDoError = createAction(apiActionTypes.API_POST_NEVER_DO_ERROR);

//EXECUTIONS----------------------------------------------------------------------
export const apiPostCreateExecution = createAction(apiActionTypes.API_POST_CREATE_EXECUTION);
export const apiPostCreateExecutionSuccess = createAction(apiActionTypes.API_POST_CREATE_EXECUTION_SUCCESS);
export const apiPostCreateExecutionError = createAction(apiActionTypes.API_POST_CREATE_EXECUTION_ERROR);

export const apiUpdateExecution = createAction(apiActionTypes.API_UPDATE_EXECUTION);
export const apiUpdateExecutionSuccess = createAction(apiActionTypes.API_UPDATE_EXECUTION_SUCCESS);
export const apiUpdateExecutionError = createAction(apiActionTypes.API_UPDATE_EXECUTION_ERROR);

export const apiDeleteExecution = createAction(apiActionTypes.API_DELETE_EXECUTION);
export const apiDeleteExecutionSuccess = createAction(apiActionTypes.API_DELETE_EXECUTION_SUCCESS);
export const apiDeleteExecutionError = createAction(apiActionTypes.API_DELETE_EXECUTION_ERROR);

//FILES-----------------------------------------------------------------------------
export const apiUploadCommunityFile = createAction(apiActionTypes.API_UPLOAD_COMMUNITY_FILE);
export const apiUploadCommunityFileSuccess = createAction(apiActionTypes.API_UPLOAD_COMMUNITY_FILE_SUCCESS);
export const apiUploadCommunityFileError = createAction(apiActionTypes.API_UPLOAD_COMMUNITY_FILE_ERROR);

export const apiUploadFile = createAction(apiActionTypes.API_UPLOAD_FILE);
export const apiUploadFileSuccess = createAction(apiActionTypes.API_UPLOAD_FILE_SUCCESS);
export const apiUploadFileError = createAction(apiActionTypes.API_UPLOAD_FILE_ERROR);

export const apiDeleteFile = createAction(apiActionTypes.API_DELETE_FILE);
export const apiDeleteFileSuccess = createAction(apiActionTypes.API_DELETE_FILE_SUCCESS);
export const apiDeleteFileError = createAction(apiActionTypes.API_DELETE_FILE_ERROR);

export const apiDeleteCommunityFile = createAction(apiActionTypes.API_DELETE_COMMUNITY_FILE);
export const apiDeleteCommunityFileSuccess = createAction(apiActionTypes.API_DELETE_COMMUNITY_FILE_SUCCESS);
export const apiDeleteCommunityFileError = createAction(apiActionTypes.API_DELETE_COMMUNITY_FILE_ERROR);

export const apiGetFileDetail = createAction(apiActionTypes.API_GET_FILE_DETAIL);
export const apiGetFileDetailSuccess = createAction(apiActionTypes.API_GET_FILE_DETAIL_SUCCESS);
export const apiGetFileDetailError = createAction(apiActionTypes.API_GET_FILE_DETAIL_ERROR);

export const apiDownloadFile = createAction(apiActionTypes.API_DOWNLOAD_FILE);
export const apiDownloadFileSuccess = createAction(apiActionTypes.API_DOWNLOAD_FILE_SUCCESS);
export const apiDownloadFileError = createAction(apiActionTypes.API_DOWNLOAD_FILE_ERROR);

export const apiDownloadTokenFile = createAction(apiActionTypes.API_DOWNLOAD_TOKEN_FILE);
export const apiDownloadTokenFileSuccess = createAction(apiActionTypes.API_DOWNLOAD_TOKEN_FILE_SUCCESS);
export const apiDownloadTokenFileError = createAction(apiActionTypes.API_DOWNLOAD_TOKEN_FILE_ERROR);

export const apiDownloadCommunityFiles = createAction(apiActionTypes.API_DOWNLOAD_COMMUNITY_FILES);
export const apiDownloadCommunityFilesSuccess = createAction(apiActionTypes.API_DOWNLOAD_COMMUNITY_FILES_SUCCESS);
export const apiDownloadCommunityFilesError = createAction(apiActionTypes.API_DOWNLOAD_COMMUNITY_FILES_ERROR);

//ANNUALPLANS-----------------------------------------------------------------------------
export const apiGetAnnualPlans = createAction(apiActionTypes.API_GET_ANNUAL_PLANS);
export const apiGetAnnualPlansSuccess = createAction(apiActionTypes.API_GET_ANNUAL_PLANS_SUCCESS);
export const apiGetAnnualPlansError = createAction(apiActionTypes.API_GET_ANNUAL_PLANS_ERROR);
export const apiResetAnnualPlans = createAction(apiActionTypes.API_RESET_ANNUAL_PLANS);

export const apiPostCreateAnnualPlan = createAction(apiActionTypes.API_POST_CREATE_ANNUAL_PLAN);
export const apiCreateAnnualPlanSuccess = createAction(apiActionTypes.API_CREATE_ANNUAL_PLAN_SUCCESS);
export const apiCreateAnnualPlanError = createAction(apiActionTypes.API_CREATE_ANNUAL_PLAN_ERROR);

export const apiPostCompleteAnnualPlan = createAction(apiActionTypes.API_POST_COMPLETE_ANNUAL_PLAN);
export const apiCompleteAnnualPlanSuccess = createAction(apiActionTypes.API_COMPLETE_ANNUAL_PLAN_SUCCESS);
export const apiCompleteAnnualPlanError = createAction(apiActionTypes.API_COMPLETE_ANNUAL_PLAN_ERROR);

export const apiDeleteAnnualPlan = createAction(apiActionTypes.API_DELETE_ANNUAL_PLAN);
export const apiDeleteAnnualPlanSuccess = createAction(apiActionTypes.API_DELETE_ANNUAL_PLAN_SUCCESS);
export const apiDeleteAnnualPlanError = createAction(apiActionTypes.API_DELETE_ANNUAL_PLAN_ERROR);

export const apiUpdateInterventionAnnualPlan = createAction(apiActionTypes.API_UPDATE_INTERVENTION_ANNUAL_PLAN);
export const apiUpdateInterventionAnnualPlanSuccess = createAction(apiActionTypes.API_UPDATE_INTERVENTION_ANNUAL_PLAN_SUCCESS);
export const apiUpdateInterventionAnnualPlanError = createAction(apiActionTypes.API_UPDATE_INTERVENTION_ANNUAL_PLAN_ERROR);

export const apiDownloadAnnualPlan = createAction(apiActionTypes.API_DOWNLOAD_ANNUAL_PLAN);
export const apiDownloadAnnualPlanSuccess = createAction(apiActionTypes.API_DOWNLOAD_ANNUAL_PLAN_SUCCESS);
export const apiDownloadAnnualPlanError = createAction(apiActionTypes.API_DOWNLOAD_ANNUAL_PLAN_ERROR);

export const apiPostInProcedureAnnualPlan = createAction(apiActionTypes.API_POST_IN_PROCEDURE_ANNUAL_PLAN);
export const apiInProcedureAnnualPlanSuccess = createAction(apiActionTypes.API_IN_PROCEDURE_ANNUAL_PLAN_SUCCESS);
export const apiInProcedureAnnualPlanError = createAction(apiActionTypes.API_IN_PROCEDURE_ANNUAL_PLAN_ERROR);


//COMMONERS----------------------------------------------------------------------
export const apiGetCommoners = createAction(apiActionTypes.API_GET_COMMONERS);
export const apiGetCommonersSuccess = createAction(apiActionTypes.API_GET_COMMONERS_SUCCESS);
export const apiGetCommonersError = createAction(apiActionTypes.API_GET_COMMONERS_ERROR);
export const apiResetCommoners = createAction(apiActionTypes.API_RESET_COMMONERS);

export const apiCreateCommoner = createAction(apiActionTypes.API_CREATE_COMMONER);
export const apiCreateCommonerSuccess = createAction(apiActionTypes.API_CREATE_COMMONER_SUCCESS);
export const apiCreateCommonerError = createAction(apiActionTypes.API_CREATE_COMMONER_ERROR);

export const apiDeleteCommoner = createAction(apiActionTypes.API_DELETE_COMMONER);
export const apiDeleteCommonerSuccess = createAction(apiActionTypes.API_DELETE_COMMONER_SUCCESS);
export const apiDeleteCommonerError = createAction(apiActionTypes.API_DELETE_COMMONER_ERROR);

export const apiUpdateCommoner = createAction(apiActionTypes.API_UPDATE_COMMONER);
export const apiUpdateCommonerSuccess = createAction(apiActionTypes.API_UPDATE_COMMONER_SUCCESS);
export const apiUpdateCommonerError = createAction(apiActionTypes.API_UPDATE_COMMONER_ERROR);

export const apiInviteCommoner = createAction(apiActionTypes.API_INVITE_COMMONER);
export const apiInviteCommonerSuccess = createAction(apiActionTypes.API_INVITE_COMMONER_SUCCESS);
export const apiInviteCommonerError = createAction(apiActionTypes.API_INVITE_COMMONER_ERROR);

export const apiActivateCommoner = createAction(apiActionTypes.API_ACTIVATE_COMMONER);
export const apiActivateCommonerSuccess = createAction(apiActionTypes.API_ACTIVATE_COMMONER_SUCCESS);
export const apiActivateCommonerError = createAction(apiActionTypes.API_ACTIVATE_COMMONER_ERROR);

export const apiDeactivateCommoner = createAction(apiActionTypes.API_DEACTIVATE_COMMONER);
export const apiDeactivateCommonerSuccess = createAction(apiActionTypes.API_DEACTIVATE_COMMONER_SUCCESS);
export const apiDeactivateCommonerError = createAction(apiActionTypes.API_DEACTIVATE_COMMONER_ERROR);

//BUSINESS----------------------------------------------------------------------
export const apiGetBusiness = createAction(apiActionTypes.API_GET_BUSINESS);
export const apiGetBusinessSuccess = createAction(apiActionTypes.API_GET_BUSINESS_SUCCESS);
export const apiGetBusinessError = createAction(apiActionTypes.API_GET_BUSINESS_ERROR);

export const apiGetBusinessWithLocation = createAction(apiActionTypes.API_GET_BUSINESS_WITH_LOCATION);
export const apiGetBusinessWithLocationSuccess = createAction(apiActionTypes.API_GET_BUSINESS_WITH_LOCATION_SUCCESS);
export const apiGetBusinessWithLocationError = createAction(apiActionTypes.API_GET_BUSINESS_WITH_LOCATION_ERROR);

//MADERAPLUS----------------------------------------------------------------------
export const apiMaderaPlusGetSpecies = createAction(apiActionTypes.MADERA_PLUS_GET_SPECIES);
export const apiMaderaPlusGetSpeciesSuccess = createAction(apiActionTypes.MADERA_PLUS_GET_SPECIES_SUCCESS);
export const apiMaderaPlusGetSpeciesError = createAction(apiActionTypes.MADERA_PLUS_GET_SPECIES_ERROR);

export const apiMaderaPlusGetDistrict = createAction(apiActionTypes.MADERA_PLUS_GET_DISTRICT);
export const apiMaderaPlusGetDistrictSuccess = createAction(apiActionTypes.MADERA_PLUS_GET_DISTRICT_SUCCESS);
export const apiMaderaPlusGetDistrictError = createAction(apiActionTypes.MADERA_PLUS_GET_DISTRICT_ERROR);

export const apiMaderaPlusGetData = createAction(apiActionTypes.MADERA_PLUS_GET_DATA);
export const apiMaderaPlusGetDataSuccess = createAction(apiActionTypes.MADERA_PLUS_GET_DATA_SUCCESS);
export const apiMaderaPlusGetDataError = createAction(apiActionTypes.MADERA_PLUS_GET_DATA_ERROR);

export const apiPostUpdateElasticModule = createAction(apiActionTypes.API_POST_UPDATE_ELASTIC_MODULE);
export const apiPostUpdateElasticModuleSuccess = createAction(apiActionTypes.API_POST_UPDATE_ELASTIC_MODULE_SUCCESS);
export const apiPostUpdateElasticModuleError = createAction(apiActionTypes.API_POST_UPDATE_ELASTIC_MODULE_ERROR);

export const apiPostProspectingPoints = createAction(apiActionTypes.API_POST_PROSPECTING_POINTS);
export const apiPostProspectingPointsSuccess = createAction(apiActionTypes.API_POST_PROSPECTING_POINTS_SUCCESS);
export const apiPostProspectingPointsError = createAction(apiActionTypes.API_POST_PROSPECTING_POINTS_ERROR);

export const apiGetProspectingPoints = createAction(apiActionTypes.API_GET_PROSPECTING_POINTS);
export const apiGetProspectingPointsSuccess = createAction(apiActionTypes.API_GET_PROSPECTING_POINTS_SUCCESS);
export const apiGetProspectingPointsError = createAction(apiActionTypes.API_GET_PROSPECTING_POINTS_ERROR);

export const apiDeleteProspectingPoints = createAction(apiActionTypes.API_DELETE_PROSPECTING_POINTS);
export const apiDeleteProspectingPointsSuccess = createAction(apiActionTypes.API_DELETE_PROSPECTING_POINTS_SUCCESS);
export const apiDeleteProspectingPointsError = createAction(apiActionTypes.API_DELETE_PROSPECTING_POINTS_ERROR);

//ACCOUNT BOOK----------------------------------------------------------------------
export const apiGetAccountEntries = createAction(apiActionTypes.API_GET_ACCOUNT_ENTRIES);
export const apiGetAccountEntriesSuccess = createAction(apiActionTypes.API_GET_ACCOUNT_ENTRIES_SUCCESS);
export const apiGetAccountEntriesError = createAction(apiActionTypes.API_GET_ACCOUNT_ENTRIES_ERROR);
export const apiResetAccountEntries = createAction(apiActionTypes.API_RESET_ACCOUNT_ENTRIES);

export const apiCreateAccountEntry = createAction(apiActionTypes.API_CREATE_ACCOUNT_ENTRY);
export const apiCreateAccountEntrySuccess = createAction(apiActionTypes.API_CREATE_ACCOUNT_ENTRY_SUCCESS);
export const apiCreateAccountEntryError = createAction(apiActionTypes.API_CREATE_ACCOUNT_ENTRY_ERROR);

export const apiDeleteAccountEntries = createAction(apiActionTypes.API_DELETE_ACCOUNT_ENTRY);
export const apiDeleteAccountEntrySuccess = createAction(apiActionTypes.API_DELETE_ACCOUNT_ENTRY_SUCCESS);
export const apiDeleteAccountEntryError = createAction(apiActionTypes.API_DELETE_ACCOUNT_ENTRY_ERROR);

export const apiUpdateAccountEntry = createAction(apiActionTypes.API_UPDATE_ACCOUNT_ENTRY);
export const apiUpdateAccountEntrySuccess = createAction(apiActionTypes.API_UPDATE_ACCOUNT_ENTRY_SUCCESS);
export const apiUpdateAccountEntryError = createAction(apiActionTypes.API_UPDATE_ACCOUNT_ENTRY_ERROR);

//BATCHES---------------------------------------------------------------------------------

export const apiGetBatches= createAction(apiActionTypes.API_GET_BATCHES);
export const apiGetBatchesSuccess = createAction(apiActionTypes.API_GET_BATCHES_SUCCESS);
export const apiGetBatchesError= createAction(apiActionTypes.API_GET_BATCHES_ERROR);
export const apiResetBatches= createAction(apiActionTypes.API_RESET_BATCHES);

export const apiPostCreateBatch = createAction(apiActionTypes.API_POST_CREATE_BATCH);
export const apiPostCreateBatchSuccess = createAction(apiActionTypes.API_POST_CREATE_BATCH_SUCCESS);
export const apiPostCreateBatchError = createAction(apiActionTypes.API_POST_CREATE_BATCH_ERROR);

export const apiPostDeleteBatch = createAction(apiActionTypes.API_POST_DELETE_BATCH);
export const apiPostDeleteBatchSuccess = createAction(apiActionTypes.API_POST_DELETE_BATCH_SUCCESS);
export const apiPostDeleteBatchError = createAction(apiActionTypes.API_POST_DELETE_BATCH_ERROR);

export const apiPostUpdateBatch = createAction(apiActionTypes.API_POST_UPDATE_BATCH);
export const apiPostUpdateBatchSuccess = createAction(apiActionTypes.API_POST_UPDATE_BATCH_SUCCESS);
export const apiPostUpdateBatchError = createAction(apiActionTypes.API_POST_UPDATE_BATCH_ERROR);

//MULTIPLE--------------------------------------------------------------------------------
export const apiPostMultipleExecution = createAction(apiActionTypes.API_POST_MULTIPLE_EXECUTION);
export const apiPostMultipleExecutionSuccess = createAction(apiActionTypes.API_POST_MULTIPLE_EXECUTION_SUCCESS);
export const apiPostMultipleExecutionError = createAction(apiActionTypes.API_POST_MULTIPLE_EXECUTION_ERROR);

export const apiPostCreateMultipleInterventions = createAction(apiActionTypes.API_CREATE_MULTIPLE_INTERVENTIONS);
export const apiPostCreateMultipleInterventionsSuccess = createAction(apiActionTypes.API_CREATE_MULTIPLE_INTERVENTIONS_SUCCESS);
export const apiPostCreateMultipleInterventionsError = createAction(apiActionTypes.API_CREATE_MULTIPLE_INTERVENTIONS_ERROR);

export const apiPostCreateMultiplePlantations = createAction(apiActionTypes.API_CREATE_MULTIPLE_PLANTATIONS);
export const apiPostCreateMultiplePlantationsSuccess = createAction(apiActionTypes.API_CREATE_MULTIPLE_PLANTATIONS_SUCCESS);
export const apiPostCreateMultiplePlantationsError = createAction(apiActionTypes.API_CREATE_MULTIPLE_PLANTATIONS_ERROR);

export const apiPostUpdateMultipleInterventions = createAction(apiActionTypes.API_UPDATE_MULTIPLE_INTERVENTIONS);
export const apiUpdateMultipleInterventionsSuccess = createAction(apiActionTypes.API_UPDATE_MULTIPLE_INTERVENTIONS_SUCCESS);
export const apiUpdateMultipleInterventionsError = createAction(apiActionTypes.API_UPDATE_MULTIPLE_INTERVENTIONS_ERROR);

const IMAGE_JPEG = 'image/jpeg';

export const downloadFile = ({fileId, content_type}, downloadToken) => {
  content_type === IMAGE_JPEG ?
    window.open('about:blank', '_blank')
      .document.write(`<img src='${BASE_URL}/attachments/${fileId}/download/?token=${downloadToken}' style='max-width:100vw' alt='Imagen de comunidad'/>`)
    : window.open(ENDPOINTS.downloadFile(fileId, downloadToken));
};
