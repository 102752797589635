import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

//STYLES
const label = {
  fontWeight: 900,
  paddingRight: 1.25,
};

const MenuData = ({
  area,
  refcat,
  stratum,
}) => {
  return <>
    <Box display="flex" flexDirection='row' alignItems="flex-start">
      <Typography sx={label}>Ref. Catastral:</Typography>
      <Typography>{refcat}</Typography>
    </Box>
    <Box display="flex" flexDirection='row' alignItems="flex-start">
      <Typography sx={label}>Estrato:</Typography>
      <Typography>{!stratum ? 'Sin estratificar' : stratum}</Typography>
    </Box>
    <Box display="flex" flexDirection="row" alignItems="flex-start">
      <Typography sx={label}>Superficie:</Typography>
      <Typography>{area} Ha</Typography>
    </Box>

  </>;
};

MenuData.propTypes = {
  area: PropTypes.number.isRequired,
  refcat: PropTypes.string,
  stratum: PropTypes.string.isRequired,
};

export default MenuData;